import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import About from "../About/About";
import Blog from "../Blog/Blog";
import BlogDetails from "../BlogDetails/BlogDetails";
import Career from "../Career/Career";
import Client from "../Client/Client";
import Contact from "../Contact/Contact";
import Faq from "../Faq/Faq";
import Portfolio from "../Portfolio/Portfolio";
import Privacy from "../Privacy/Privacy";
import Services from "../Services/Services";
import Terms from "../Terms/Terms";
import Enquiry from "../Enquiry/Enquiry";
import CareerDetails from "../CareerDetails/CareerDetails";
import BlogSearch from '../BlogSearch/BlogSearch'
import BlogSearchCategory from '../BlogSearchCategory/BlogSearchCategory'

const Router = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/blogDetails/:id" element={<BlogDetails />} />
        <Route path="/blog/:value" element={<BlogSearchCategory />} />
        <Route path="/blog/search/:value" element={<BlogSearch />} />

        <Route path="/careers" element={<Career />} />
        <Route path="/client" element={<Client />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/enquiry" element={<Enquiry />} />
        <Route path="/faq" element={<Faq />} />

        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/privacy/washwoosh" element={<Privacy />} />
        <Route path="/privacy/hrms" element={<Privacy />} />
        <Route path="/privacy/sk-board" element={<Privacy />} />
        <Route path="/services" element={<Services />} />
        <Route path="/term" element={<Terms />} />
        <Route path="/career-details/:id" element={<CareerDetails />} />

        {/* ITSolutions */}
        <Route path="/ITSolutions/home" element={<Home />} />
        <Route path="/ITSolutions/career" element={<Career />} />
      </Routes>
    </div>
  );
};

export default Router;
